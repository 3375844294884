import { CircularProgress, InputAdornment, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useDebounce } from "use-debounce";

export default function SearchField({ initialSearchText, onChange }) {
  const [searchText, setSearchText] = useState(initialSearchText || "");
  const [searchTextDebounced] = useDebounce(searchText, 500);
  const [filtering, setFiltering] = useState(false);
  useEffect(() => {
    setFiltering(true);
  }, [searchText]);
  useEffect(() => {
    setFiltering(false);
    onChange(searchTextDebounced);
    // eslint-disable-next-line
  }, [searchTextDebounced]);
  return (
    <TextField
      fullWidth
      label="Search"
      variant="outlined"
      size="small"
      value={searchText}
      onChange={(e) => setSearchText(e.target.value)}
      InputProps={
        filtering
          ? {
              endAdornment: (
                <InputAdornment position="end">
                  <CircularProgress size="1rem" />
                </InputAdornment>
              ),
            }
          : undefined
      }
    />
  );
}
