import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";

const config = {
  apiKey: "AIzaSyDvcoNVB3lyrF2ZGN828hbKhSzuqv6UnZs",
  authDomain: "e2a741.firebaseapp.com",
  projectId: "e2a741",
  storageBucket: "e2a741.appspot.com",
  messagingSenderId: "1054083027809",
  appId: "1:1054083027809:web:8d80d6b66e53e9593d2b20",
  measurementId: "G-Q1PCDY6878"
};

const app = initializeApp(config);
const analytics = getAnalytics(app);
const storage = getStorage(app);

export { app, analytics, storage };
