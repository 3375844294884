import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import {
  Avatar,
  Badge,
  Box,
  Breadcrumbs,
  CircularProgress,
  Collapse,
  Grow,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { logEvent } from "firebase/analytics";
import { getBlob, ref } from "firebase/storage";
import { Fragment, useState } from "react";
import { analytics, storage } from "../firebase";
import HistoryTable from "./HistoryTable";
import SearchForm from "./SearchForm";
import SearchField from "./commons/SearchField";

const collections = {
  "ps5-best-selling-games": "PS5 Best Selling Games",
  "ps4-best-selling-games": "PS4 Best Selling Games",
  "xbox-best-selling-games": "Xbox Best Selling Games",
};

const countries = {
  us: "United States",
  jp: "Japan",
  kr: "Korea",
  de: "Germany",
  gb: "United Kingdom",
  fr: "France",
  ca: "Canada",
  es: "Spain",
  it: "Italy",
  mx: "Mexico",
  br: "Brasil",
  au: "Australia",
};

export default function Root() {
  const [state, setState] = useState({
    status: "NOT_STARTED",
    allGames: [],
    filteredGames: [],
    visibleGames: [],
    searchText: "",
    page: 0,
    rowsPerPage: 20,
  });
  const [hoverGame, setHoverGame] = useState();
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down("sm"));

  const getFilteredGames = (allGames, searchText) => {
    if (searchText) {
      const search = searchText.toLowerCase();
      return allGames.filter((game) => game.search.includes(search));
    } else {
      return allGames;
    }
  };

  const getVisibleGames = (filteredGames, page, rowsPerPage) => {
    const start = page * rowsPerPage;
    const end = start + rowsPerPage;
    return filteredGames.slice(start, end);
  };

  const onClickSearch = async ({ collection, country, date }) => {
    logEvent(analytics, "select_content", {
      content_type: "snapshot",
      content_id: `${collection}/${country}/${date}`,
    });
    const snapshotPath = `${collection}/${country}/${date}/snapshot`;
    const historyPath = `${collection}/${country}/${date}/history`;
    try {
      setState({ status: "SEARCHING" });
      const snapshotBlob = await getBlob(ref(storage, snapshotPath));
      const snapshotText = await snapshotBlob.text();
      const snapshotJson = JSON.parse(snapshotText);
      const historyBlob = await getBlob(ref(storage, historyPath));
      const historyText = await historyBlob.text();
      const historyJson = JSON.parse(historyText);
      const games = snapshotJson.games.map((game, index) => ({
        ...game,
        position: index + 1,
        history: {
          positions: historyJson.positions[game.id],
          prices: historyJson.prices[game.id],
        },
        search: game.name.toLowerCase(),
      }));
      const filteredGames = getFilteredGames(games, state.searchText);
      const visibleGames = getVisibleGames(filteredGames, 0, state.rowsPerPage);
      setState({
        collection,
        country,
        date,
        timestamp: snapshotJson.timestamp,
        status: "FOUND",
        allGames: games,
        filteredGames: filteredGames,
        visibleGames: visibleGames,
        searchText: state.searchText,
        page: 0,
        rowsPerPage: state.rowsPerPage,
      });
    } catch (e) {
      if (e.code === "storage/object-not-found") {
        setState({ status: "NOT_FOUND" });
      } else {
        setState({ status: "ERROR" });
      }
    }
  };

  const onChangeSearchText = (searchText) => {
    logEvent(analytics, "search", {
      search_term: searchText,
    });
    const filteredGames = getFilteredGames(state.allGames, searchText);
    const visibleGames = getVisibleGames(filteredGames, 0, state.rowsPerPage);
    setState({
      ...state,
      filteredGames: filteredGames,
      visibleGames: visibleGames,
      searchText: searchText,
      page: 0,
    });
  };

  const onChangePage = (page) => {
    const visibleGames = getVisibleGames(
      state.filteredGames,
      page,
      state.rowsPerPage
    );
    setState({
      ...state,
      visibleGames: visibleGames,
      page: page,
    });
  };

  const onChangeRowsPerPage = (rowsPerPage) => {
    const visibleGames = getVisibleGames(state.filteredGames, 0, rowsPerPage);
    setState({
      ...state,
      visibleGames: visibleGames,
      page: 0,
      rowsPerPage: rowsPerPage,
    });
  };

  const onClickOpenGame = (game) => {
    if (state.openGame === game.id) {
      setState({
        ...state,
        openGame: undefined,
      });
    } else {
      setState({
        ...state,
        openGame: game.id,
      });
    }
  };

  const {
    collection,
    country,
    date,
    status,
    filteredGames,
    visibleGames,
    searchText,
    page,
    rowsPerPage,
    openGame,
  } = state;

  return (
    <Stack
      direction={xs ? "column" : "row"}
      padding={2}
      spacing={2}
      sx={{ minHeight: "100vh" }}
    >
      <Box>
        <Paper variant="outlined">
          <SearchForm
            collections={collections}
            countries={countries}
            onClickSearch={onClickSearch}
          />
        </Paper>
      </Box>
      {status === "NOT_STARTED" && (
        <Box
          sx={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography>Enter search criteria and click SUBMIT</Typography>
        </Box>
      )}
      {status === "SEARCHING" && (
        <Box
          sx={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {status === "NOT_FOUND" && (
        <Box
          sx={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography>No data was found for the search criteria</Typography>
        </Box>
      )}
      {status === "ERROR" && (
        <Box
          sx={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography>An unexpected error has occured</Typography>
        </Box>
      )}
      {status === "FOUND" && (
        <TableContainer component={Paper} variant="outlined">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell colSpan={4}>
                  <Breadcrumbs separator=">">
                    <Typography>
                      {collections[collection] || "Unknown collection"}
                    </Typography>
                    <Typography>
                      {countries[country] || "Unknown country"}
                    </Typography>
                    <Typography>{date}</Typography>
                  </Breadcrumbs>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={4}>
                  <SearchField
                    initialSearchText={searchText}
                    onChange={onChangeSearchText}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TablePagination
                  colSpan={4}
                  count={filteredGames.length}
                  labelRowsPerPage="Page size:"
                  onPageChange={(e, p) => onChangePage(p)}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  rowsPerPageOptions={[20, 50, 100]}
                  onRowsPerPageChange={(e) =>
                    onChangeRowsPerPage(e.target.value)
                  }
                  showFirstButton={true}
                  showLastButton={true}
                />
              </TableRow>
            </TableHead>
            <TableBody>
              {searchText && visibleGames.length === 0 && (
                <TableRow>
                  <TableCell colSpan={4} align="center" sx={{ py: 8 }}>
                    No games found
                  </TableCell>
                </TableRow>
              )}
              {visibleGames.map((game) => (
                <Fragment key={game.id}>
                  <TableRow>
                    <TableCell width={72} sx={{ border: 0 }}>
                      <Badge
                        badgeContent={game.position}
                        color="primary"
                        max={999}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                      >
                        <Avatar
                          src={game.image}
                          variant="rounded"
                          onMouseEnter={() => setHoverGame(game.id)}
                          onMouseLeave={() => setHoverGame()}
                        />
                      </Badge>
                      <Grow in={hoverGame === game.id}>
                        <div
                          style={{
                            position: "absolute",
                            zIndex: 99999,
                            marginLeft: 70,
                          }}
                        >
                          <img
                            alt={game.name}
                            src={game.image}
                            width={200}
                            style={{ borderRadius: 4 }}
                          />
                        </div>
                      </Grow>
                    </TableCell>
                    <TableCell sx={{ border: 0 }}>
                      <Typography>{game.name}</Typography>
                    </TableCell>
                    <TableCell align="right" width={1} sx={{ border: 0 }}>
                      <Typography noWrap>
                        {game.price} {game.currency}
                      </Typography>
                    </TableCell>
                    <TableCell align="right" width={1} sx={{ border: 0 }}>
                      <IconButton onClick={() => onClickOpenGame(game)}>
                        {openGame === game.id ? (
                          <KeyboardArrowUp />
                        ) : (
                          <KeyboardArrowDown />
                        )}
                      </IconButton>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={4} sx={{ padding: 0 }}>
                      <Collapse
                        in={openGame === game.id}
                        timeout="auto"
                        unmountOnExit
                        sx={{ padding: 2 }}
                      >
                        <HistoryTable
                          dates={Object.keys(game.history.positions)}
                          positions={game.history.positions}
                          prices={game.history.prices}
                          currency={game.currency}
                        />
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </Fragment>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  colSpan={4}
                  count={filteredGames.length}
                  labelRowsPerPage="Page size:"
                  onPageChange={(e, p) => onChangePage(p)}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  rowsPerPageOptions={[20, 50, 100]}
                  onRowsPerPageChange={(e) =>
                    onChangeRowsPerPage(e.target.value)
                  }
                  showFirstButton={true}
                  showLastButton={true}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      )}
    </Stack>
  );
}
