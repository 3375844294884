import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
} from "@mui/material";
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useState } from "react";

export default function SearchForm({ collections, countries, onClickSearch }) {
  const [collection, setCollection] = useState("ps5-best-selling-games");
  const [country, setCountry] = useState("us");
  const [date, setDate] = useState(dayjs());
  return (
    <Stack padding={2} spacing={2}>
      <FormControl fullWidth>
        <InputLabel id="collection">Collection</InputLabel>
        <Select
          labelId="collection"
          label="Collection"
          value={collection}
          onChange={(e) => setCollection(e.target.value)}
        >
          {Object.keys(collections).map((key) => (
            <MenuItem key={key} value={key}>
              {collections[key]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel id="country">Country</InputLabel>
        <Select
          labelId="country"
          label="Country"
          value={country}
          onChange={(e) => setCountry(e.target.value)}
        >
          {Object.keys(countries).map((key) => (
            <MenuItem key={key} value={key}>
              {countries[key]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Paper variant="outlined">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateCalendar disableFuture value={date} onChange={setDate} />
        </LocalizationProvider>
      </Paper>
      <Button
        size="large"
        variant="contained"
        onClick={() =>
          onClickSearch({
            collection,
            country,
            date: date.format("YYYY-MM-DD"),
          })
        }
      >
        Submit
      </Button>
    </Stack>
  );
}
