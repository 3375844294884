import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { useState } from "react";

export default function HistoryTable({ dates, positions, prices, currency }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(7);
  dates.sort().reverse();
  return (
    <TableContainer component={Paper} variant="outlined">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Position</TableCell>
            <TableCell>Price</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dates
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((date) => (
              <TableRow key={date}>
                <TableCell>{date}</TableCell>
                <TableCell>{positions[date]}</TableCell>
                <TableCell>
                  {prices[date]} {currency}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              colSpan={3}
              count={dates.length}
              labelRowsPerPage="Page size:"
              onPageChange={(e, p) => setPage(p)}
              page={page}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[7, 14, 21]}
              onRowsPerPageChange={(e) => setRowsPerPage(e.target.value)}
              showFirstButton={true}
              showLastButton={true}
              sx={{ borderBottom: 0 }}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
